import {
  DANIELLE_PHONE_NUMBER,
  DANIELLE_PHONE_URL,
  DANIELLE_EMAIL,
  JACOB_PHONE_NUMBER,
  JACOB_PHONE_URL,
  JACOB_EMAIL,
  ProgressIndicator,
  ProgressIndicatorStatus,
  RightArrowIcon,
  TrackedButton,
  TrackedLink,
} from '@flock/shared-ui'
import { Lightbulb } from '@mui/icons-material'
import { Box, CircularProgress, Grid, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useInvestorAccountContext } from '../../InvestorAccountContext'
import SectionLayout from '../../SharedComponents/SectionLayout'
import { OrderTaskItem } from './orderV3Types'

type ProgressIndicatorConfig = {
  text: string
  status: ProgressIndicatorStatus
  overText: string
}

export type OrderProgressState = {
  isStep1Complete: boolean
  targetCloseDate: string
  titleCompany: string
  operatorUuid: string
  isWaitingOnOtherInvestorsToCompleteSteps: boolean
  progressBarConfigs: ProgressIndicatorConfig[]

  currentTasks: OrderTaskItem[]
  uncompletedTasks: OrderTaskItem[]
  completedTasks: OrderTaskItem[]
}

const CurrentTasksComponent = (actionItem: OrderTaskItem) => {
  const { title, subtitle, isPending, onClick } = actionItem
  const [isLoading, setIsLoading] = useState<Boolean>(false)

  return (
    <TrackedLink
      variant="p1"
      sx={{
        display: 'flex',
        alignItems: 'center',
        mr: '8px',
        cursor: isPending ? 'auto' : 'pointer',
      }}
      onClick={async () => {
        if (onClick != null) {
          setIsLoading(true)
          await onClick()
          setIsLoading(false)
        }
      }}
    >
      <Box display="flex" flexDirection="row" gap="10px">
        <Box display="flex" flexDirection="column">
          <Typography
            variant="p1"
            color={isPending ? 'gray5.main' : 'green4.main'}
          >
            {title}{' '}
          </Typography>
          <Typography
            variant="p2"
            color={isPending ? 'gray5.main' : 'green4.main'}
          >
            {subtitle}
          </Typography>
        </Box>
        {!isPending &&
          (isLoading ? (
            <CircularProgress size={24} />
          ) : (
            <RightArrowIcon width="28px" height="28px" color="green4" />
          ))}
      </Box>
    </TrackedLink>
  )
}

type OrderHomeSectionProps = {
  id: string
  progressState: OrderProgressState
  openOrderStepsModal: () => void
}

const OrderV3HomeSection = (props: OrderHomeSectionProps) => {
  const { id, progressState, openOrderStepsModal } = props

  const {
    isStep1Complete,
    targetCloseDate,
    isWaitingOnOtherInvestorsToCompleteSteps,
    progressBarConfigs,
    currentTasks,
    uncompletedTasks,
    completedTasks,
    operatorUuid,
  } = progressState

  let operatorPhoneNumber = DANIELLE_PHONE_NUMBER
  let operatorPhoneUrl = DANIELLE_PHONE_URL
  let operatorEmail = DANIELLE_EMAIL
  let operatorName = 'Danielle'

  if (operatorUuid === 'f9c0e463-48a3-4b89-a7ce-437cd8cb8ad2') {
    operatorPhoneNumber = JACOB_PHONE_NUMBER
    operatorPhoneUrl = JACOB_PHONE_URL
    operatorEmail = JACOB_EMAIL
    operatorName = 'Jacob'
  }

  const waitingMessage = isWaitingOnOtherInvestorsToCompleteSteps
    ? 'On hold until other investors finish action items'
    : "We're working on a few remaining items on our end and will be in touch soon!"

  const { investorFirstName } = useInvestorAccountContext()

  let showorderStepsModalButton = false
  progressBarConfigs.forEach((config) => {
    const { text, status } = config
    if (text === 'Contribution Agreement' && status === 'complete') {
      showorderStepsModalButton = true
    }
  })

  return (
    <>
      <SectionLayout name="home-section" columns={9} id={id}>
        <Grid item xs={9}>
          <Typography variant="h2" pb="16px">
            {`Welcome, ${investorFirstName}.`}
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="8px"
          >
            {progressBarConfigs.map((config) => {
              const { text, status, overText } = config
              return (
                <ProgressIndicator
                  text={text}
                  status={status}
                  overText={overText}
                  width="100%"
                />
              )
            })}
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            height="50px"
          >
            {showorderStepsModalButton && (
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                onClick={() => {
                  openOrderStepsModal()
                }}
                sx={{ cursor: 'pointer' }}
              >
                <Lightbulb />
                <Typography variant="p3" color="moneyGreen.main">
                  Click here to see the order overview
                </Typography>
              </Box>
            )}

            <Box display="flex" flexDirection="row" alignItems="center">
              <Typography variant="p3" color="moneyGreen.main">
                {!isStep1Complete
                  ? 'Complete action items to start title'
                  : `Title in progress, estimated closing date ${targetCloseDate}`}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={6}>
          <Box display="flex" flexDirection="column" gap="36px">
            {currentTasks.length > 0 ? (
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography variant="h4" color="moneyGreen.main">
                  Current action items.
                </Typography>
                {currentTasks.map((actionItem: OrderTaskItem) =>
                  CurrentTasksComponent(actionItem)
                )}
              </Box>
            ) : (
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography variant="h4" color="moneyGreen.main">
                  Current action items.
                </Typography>
                <Typography variant="p1" color="gray5.main">
                  {waitingMessage}
                </Typography>
              </Box>
            )}
            {uncompletedTasks.length > 0 && (
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography variant="h4" color="gray8.main">
                  Next steps.
                </Typography>
                {uncompletedTasks.length > 0 ? (
                  uncompletedTasks.map((actionItem: OrderTaskItem) => {
                    const { title } = actionItem
                    return <Typography variant="p2">{title}</Typography>
                  })
                ) : (
                  <Typography variant="p2" color="gray5.main">
                    None, you&apos;re almost done!
                  </Typography>
                )}
              </Box>
            )}

            {completedTasks.length > 0 && (
              <Box display="flex" flexDirection="column" gap="16px">
                <Typography variant="h4" color="gray5.main">
                  Tasks you&apos;ve completed.
                </Typography>
                {completedTasks.map((actionItem: OrderTaskItem) => {
                  const { title } = actionItem
                  return (
                    <Typography
                      variant="p2"
                      color="gray5.main"
                      sx={{ textDecoration: 'line-through' }}
                    >
                      {title}
                    </Typography>
                  )
                })}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Paper
            elevation={8}
            sx={{
              borderRadius: '24px',
              background: 'rgba(255, 255, 255, 0.25)',
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="16px"
              padding="20px"
            >
              <Typography variant="p2">
                Do you have questions about the onboarding process?
              </Typography>
              <TrackedButton
                variant="secondary"
                size="smallForm"
                onClick={() => window.open(operatorPhoneUrl)}
              >
                <Typography variant="p3">Call {operatorPhoneNumber}</Typography>
              </TrackedButton>

              <TrackedButton
                size="smallForm"
                onClick={() => window.open(`mailto:${operatorEmail}`)}
              >
                <Typography variant="p2">Email {operatorName}</Typography>
              </TrackedButton>
            </Box>
          </Paper>

          {/* {progressState.propertyQuestionnairesState.status ===
                'current' && (
                <Paper
                  elevation={8}
                  sx={{
                    borderRadius: '24px',
                    background: 'rgba(255, 255, 255, 0.25)',
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    padding="24px"
                    mt="32px"
                  >
                    <Typography variant="p3">
                      Here are some documents you may need:
                      <Box component="ul" pl="16px">
                        <li>Current lease</li>
                        <li>Depreciation schedule</li>
                        <li>
                          Property management and tenant contact information
                        </li>
                        <li>HOA information</li>
                        <li>Utility company information</li>
                      </Box>
                    </Typography>
                  </Box>
                </Paper>
              )} */}
        </Grid>
      </SectionLayout>
    </>
  )
}

export default OrderV3HomeSection
