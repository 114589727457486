import {
  BathroomIcon,
  BedroomIcon,
  DimensionsIcon,
  LeftArrowFilledIcon,
  MoneyOutlinedIcon,
  PageContainer,
  scrollIntoView,
} from '@flock/shared-ui'
import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { Info } from '@mui/icons-material'
import { RouteComponentProps } from '@reach/router'
import {
  formatIntegerDollars,
  formatNumberWithCommas,
  formatPercentage,
} from '@flock/utils'
import { navigate } from 'gatsby'
import React from 'react'
import { DETAILED_ASSET_URL } from '../../constants'
import PageWrapper from '../SharedComponents/PageWrapper'
import useAssetViewPage from './useAssetViewPage'
import AssetLifecycleEventRow, {
  AssetLifecycleEventType,
} from './AssetLifecycleEventRow'
import DefaultImage from '../../images/default-home-coming-soon.png'
import { MTM, OCCUPIED } from './detailedAssetViewTypes'
import AssetLeasingChart from './AssetLeasingChart'
import AssetValuationChart from './AssetValuationChart'

const StyledInfoOutline = styled(Info)({
  width: '16px',
  height: '16px',
})

const StyledTooltip = styled((props: any) => (
  <Tooltip classes={{ popper: props.className }} {...props} />
))`
  & .MuiTooltip-tooltip {
    background-color: #e9eaeb;
    border-radius: 8px;
    padding: 8px 12px 8px 12px;
  }

  & .MuiTooltip-arrow::before {
    color: #e9eaeb;
  }
`

const MapContainer = styled('div')({
  width: 'fit-content',
  alignSelf: 'center',
  textAlign: 'center',
  position: 'relative',
  maxHeight: '288px',
  maxWidth: '288px',
})

const Marker = styled('div')({
  position: 'absolute',
  background: '#03341D',
  borderRadius: '50%',
  border: '8px solid #03341D',
  width: '8px',
  height: '8px',
  marginLeft: '132px',
  marginTop: '-162px',

  '&:after': {
    position: 'absolute',
    width: '0px',
    height: '0px',
    bottom: '-30px',
    left: '-6px',
    border: '10px solid transparent',
    borderTop: '17px solid #03341D',
    content: '""',
  },
})

const tagColorMap: Record<string, string> = {
  occupied: 'green4.main',
  mtm: 'green4.main',
  vacant: 'errorRed.main',
  renovating: 'softGold.main',
  leasing: '#FFD9B7',
}

const textColorMap: Record<string, string> = {
  occupied: '#FFFFFF',
  mtm: '#FFFFFF',
  vacant: '#FFFFFF',
  renovating: 'moneyGreen.main',
  leasing: 'moneyGreen.main',
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AssetViewPage = (
  props: RouteComponentProps & {
    assetUuid?: string
  }
) => {
  const { assetUuid } = props
  const {
    assetLoading,
    assetError,
    acquisitionDate,
    acquisitionPrice,
    tickFormatterX,
    tickFormatterY,
    tickFormatterYLeasing,
    valuationGraphData,
    leasingGraphData,
    lifecycleData,
    assetDetails,
    assetDetailsArray,
    hasValuationData,
    hasLeasingData,
    mapsUrl,
  } = useAssetViewPage({ assetUuid: assetUuid || '' })

  if (assetError) {
    navigate(DETAILED_ASSET_URL)
  }

  let eventQuarter = 0
  let eventYear = 0

  let beds: number = 0
  let baths: number = 0
  let sqft: number = 0
  let monthlyRentCents: number = 0
  let marketRentCents: number = 0
  let valuationCents: number = 0
  let grossYield: number = 0
  let unitsRentedCount: number = 0
  let primaryPhotoUrl: string = ''
  const isMultifamily = assetDetailsArray?.length > 1

  if (isMultifamily) {
    assetDetailsArray?.forEach((asset) => {
      beds += asset.beds
      baths += asset.baths
      sqft += asset.sqft
      grossYield += asset.grossYield || 0
      marketRentCents += asset.marketRentCents || 0
      if (
        asset.propertyValuationHistory &&
        asset.propertyValuationHistory.length > 0
      ) {
        valuationCents +=
          asset.propertyValuationHistory[
            asset.propertyValuationHistory.length - 1
          ]?.currentValuationCents || 0
      }
      if (primaryPhotoUrl === '' && asset.address?.primaryPhotoUrl) {
        primaryPhotoUrl = asset.address?.primaryPhotoUrl
      }
      if (
        asset.leaseStatus &&
        (asset.leaseStatus === 'mtm' || asset.leaseStatus === 'occupied')
      ) {
        monthlyRentCents += asset.monthlyRentCents || 0
        unitsRentedCount += 1
      }
    })
  }

  return (
    <PageContainer title="FLOCK | Assets" trackingName="assets">
      <PageWrapper>
        <Grid container spacing={4} columns={12} height="auto" pb="32px">
          <Grid item xs={3}>
            <Paper
              sx={{
                borderRadius: '16px',
                padding: '0px 16px 0px 0px',
                cursor: 'pointer',
              }}
              onClick={() => navigate(DETAILED_ASSET_URL)}
            >
              <Box display="flex" alignItems="center">
                <LeftArrowFilledIcon height="48px" width="48px" />
                <Typography variant="p3">Return to List</Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={9}>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography variant="h4" fontSize="32px">
                {assetDetails?.address?.streetNumber}{' '}
                {assetDetails?.address?.formattedStreet}{' '}
                {assetDetails?.address?.city}, {assetDetails?.address?.state}{' '}
                {assetDetails?.address?.zipcode}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              position: 'sticky',
              top: '8%',
              height: 'fit-content',
              zIndex: 999,
            }}
          >
            <Box display="flex" flexDirection="column" width="100%" gap="16px">
              <Box display="flex" flexDirection="column">
                <Paper
                  key="sort"
                  sx={{
                    padding: '16px 16px 16px 24px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    transition: 'background-color 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'gray1.main',
                      transition: 'background-color 0.2s ease',
                    },
                  }}
                  onClick={() => {
                    scrollIntoView('homeDetails')
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h4" fontWeight="500" fontSize="20px">
                      Home Details
                    </Typography>
                  </Box>
                </Paper>
              </Box>
              <Box display="flex" flexDirection="column" gap="8px">
                <Paper
                  key="state"
                  sx={{
                    padding: '16px 20px 16px 24px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    transition: 'background-color 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'gray1.main',
                      transition: 'background-color 0.2s ease',
                    },
                  }}
                  onClick={() => {
                    scrollIntoView('performanceMetrics')
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h4" fontWeight="500" fontSize="20px">
                      Performance Metrics
                    </Typography>
                  </Box>
                </Paper>
              </Box>
              {hasValuationData && (
                <Box display="flex" flexDirection="column">
                  <Paper
                    key="type"
                    sx={{
                      padding: '16px 20px 16px 24px',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      borderRadius: '16px',
                      boxShadow: 'none',
                      transition: 'background-color 0.2s ease-in-out',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'gray1.main',
                        transition: 'background-color 0.2s ease',
                      },
                    }}
                    onClick={() => {
                      scrollIntoView('valuationHistory')
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h4" fontWeight="500" fontSize="20px">
                        Valuation History
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              )}
              {hasLeasingData && leasingGraphData.length > 0 && (
                <Box display="flex" flexDirection="column">
                  <Paper
                    key="type"
                    sx={{
                      padding: '16px 20px 16px 24px',
                      justifyContent: 'space-between',
                      alignItems: 'baseline',
                      borderRadius: '16px',
                      boxShadow: 'none',
                      transition: 'background-color 0.2s ease-in-out',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'gray1.main',
                        transition: 'background-color 0.2s ease',
                      },
                    }}
                    onClick={() => {
                      scrollIntoView('rentalHistory')
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h4" fontWeight="500" fontSize="20px">
                        Rental History
                      </Typography>
                    </Box>
                  </Paper>
                </Box>
              )}
              <Box display="flex" flexDirection="column">
                <Paper
                  key="type"
                  sx={{
                    padding: '16px 20px 16px 24px',
                    justifyContent: 'space-between',
                    alignItems: 'baseline',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    transition: 'background-color 0.2s ease-in-out',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: 'gray1.main',
                      transition: 'background-color 0.2s ease',
                    },
                  }}
                  onClick={() => {
                    scrollIntoView('assetLifecycle')
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="h4" fontWeight="500" fontSize="20px">
                      Asset Lifecycle
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={9}>
            {assetLoading ? (
              <CircularProgress />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                width="100%"
                gap="16px"
              >
                <Paper
                  sx={{
                    padding: '24px',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    transition: 'background-color 0.2s ease-in-out',
                  }}
                  id="homeDetails"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    gap="16px"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      width="100%"
                      gap="16px"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        width="480px"
                        flexDirection="column"
                        gap="16px"
                      >
                        <Box
                          width="480px"
                          height="288px"
                          sx={{
                            borderRadius: '4px',
                            backgroundImage: assetDetails?.address
                              ?.primaryPhotoUrl
                              ? `url(${assetDetails.address?.primaryPhotoUrl})`
                              : `url(${DefaultImage})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                          }}
                        >
                          {assetDetails?.leaseStatus && !isMultifamily && (
                            <Paper
                              sx={{
                                borderRadius: '8px',
                                width: '96px',
                                height: '32px',
                                backgroundColor:
                                  tagColorMap[
                                    assetDetails?.leaseStatus || OCCUPIED
                                  ],
                                position: 'relative',
                                top: '8px',
                                left: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <Typography
                                variant="c1"
                                color={
                                  textColorMap[
                                    assetDetails?.leaseStatus || OCCUPIED
                                  ]
                                }
                              >
                                {assetDetails?.leaseStatus === MTM
                                  ? 'OCCUPIED'
                                  : assetDetails?.leaseStatus?.toUpperCase()}
                              </Typography>
                            </Paper>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-start"
                          minHeight="44px"
                        >
                          {isMultifamily ? (
                            <>
                              {unitsRentedCount > 0 ? (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pr="16px"
                                  borderRight="1px solid #E9EAEB"
                                >
                                  <Typography variant="p1">
                                    {`${formatIntegerDollars(
                                      (monthlyRentCents || 0) / 100,
                                      true
                                    )}`}
                                  </Typography>
                                  <Typography variant="p3">
                                    total rent/mo
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pr="16px"
                                  borderRight="1px solid #E9EAEB"
                                >
                                  <Typography variant="p1">
                                    {(marketRentCents || 0) > 0
                                      ? `${formatIntegerDollars(
                                          (marketRentCents || 0) / 100,
                                          true
                                        )}`
                                      : '-'}
                                  </Typography>
                                  <Typography variant="p3">
                                    market rent/mo
                                  </Typography>
                                </Box>
                              )}
                            </>
                          ) : (
                            <>
                              {(assetDetails?.leaseStatus === OCCUPIED ||
                                assetDetails?.leaseStatus === MTM) &&
                              (assetDetails?.monthlyRentCents || 0) > 0 ? (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pr="16px"
                                  borderRight="1px solid #E9EAEB"
                                >
                                  <Typography variant="p1">
                                    {`${formatIntegerDollars(
                                      (assetDetails?.monthlyRentCents || 0) /
                                        100,
                                      true
                                    )}`}
                                  </Typography>
                                  <Typography variant="p3">
                                    total rent/mo
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pr="16px"
                                  borderRight="1px solid #E9EAEB"
                                >
                                  <Typography variant="p1">
                                    {(assetDetails?.marketRentCents || 0) > 0
                                      ? `${formatIntegerDollars(
                                          (assetDetails?.marketRentCents || 0) /
                                            100,
                                          true
                                        )}`
                                      : '-'}
                                  </Typography>
                                  <Typography variant="p3">
                                    market rent/mo
                                  </Typography>
                                </Box>
                              )}
                            </>
                          )}
                          {isMultifamily ? (
                            <>
                              {valuationCents !== 0 ? (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pl="16px"
                                  pr="16px"
                                  borderRight="1px solid #E9EAEB"
                                >
                                  <Typography variant="p1">
                                    {`${formatIntegerDollars(
                                      valuationCents / 100,
                                      true
                                    )}`}
                                  </Typography>
                                  <Typography variant="p3">
                                    valuation
                                  </Typography>
                                </Box>
                              ) : (
                                assetDetails?.acquisitionPriceCents !== 0 && (
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    pl="16px"
                                    pr="16px"
                                    borderRight="1px solid #E9EAEB"
                                  >
                                    <Typography variant="p1">
                                      {`${formatIntegerDollars(
                                        (assetDetails?.acquisitionPriceCents ||
                                          0) / 100,
                                        true
                                      )}`}
                                    </Typography>
                                    <Typography variant="p3">
                                      acq price
                                    </Typography>
                                  </Box>
                                )
                              )}
                            </>
                          ) : (
                            <>
                              {assetDetails?.propertyValuationHistory &&
                              assetDetails?.propertyValuationHistory[
                                assetDetails?.propertyValuationHistory.length -
                                  1
                              ] &&
                              assetDetails?.propertyValuationHistory[
                                assetDetails?.propertyValuationHistory.length -
                                  1
                              ]?.currentValuationCents !== 0 ? (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pl="16px"
                                  pr="16px"
                                  borderRight="1px solid #E9EAEB"
                                >
                                  <Typography variant="p1">
                                    {`${formatIntegerDollars(
                                      (assetDetails?.propertyValuationHistory[
                                        assetDetails?.propertyValuationHistory
                                          .length - 1
                                      ]?.currentValuationCents || 0) / 100,
                                      true
                                    )}`}
                                  </Typography>
                                  <Typography variant="p3">
                                    valuation
                                  </Typography>
                                </Box>
                              ) : (
                                assetDetails?.acquisitionPriceCents !== 0 && (
                                  <Box
                                    display="flex"
                                    flexDirection="column"
                                    pl="16px"
                                    pr="16px"
                                    borderRight="1px solid #E9EAEB"
                                  >
                                    <Typography variant="p1">
                                      {`${formatIntegerDollars(
                                        (assetDetails?.acquisitionPriceCents ||
                                          0) / 100,
                                        true
                                      )}`}
                                    </Typography>
                                    <Typography variant="p3">
                                      acq price
                                    </Typography>
                                  </Box>
                                )
                              )}
                            </>
                          )}
                          {isMultifamily
                            ? grossYield !== 0 && (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pl="16px"
                                >
                                  <Typography variant="p1">
                                    {`${formatPercentage(grossYield || 0, 1)}`}
                                  </Typography>
                                  <Typography variant="p3">
                                    gross yield
                                  </Typography>
                                </Box>
                              )
                            : assetDetails?.grossYield !== null &&
                              assetDetails?.grossYield !== undefined &&
                              assetDetails?.grossYield !== 0 && (
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  pl="16px"
                                >
                                  <Typography variant="p1">
                                    {`${formatPercentage(
                                      assetDetails?.grossYield || 0,
                                      1
                                    )}`}
                                  </Typography>
                                  <Typography variant="p3">
                                    gross yield
                                  </Typography>
                                </Box>
                              )}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        justifyContent="center"
                        width="288px"
                        flexDirection="column"
                        gap="16px"
                      >
                        <MapContainer>
                          <Box
                            component="img"
                            height="288px"
                            width="288px"
                            sx={{ borderRadius: '4px' }}
                            src={mapsUrl}
                          />
                          <Marker />
                        </MapContainer>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="flex-end"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            pr="8px"
                            borderRight="1px solid #E9EAEB"
                          >
                            <Typography variant="p2">
                              {isMultifamily ? beds : assetDetails?.beds}
                            </Typography>
                            <Typography variant="p3">bed</Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            pr="8px"
                            pl="8px"
                            borderRight="1px solid #E9EAEB"
                          >
                            <Typography variant="p2">
                              {isMultifamily ? baths : assetDetails?.baths}
                            </Typography>
                            <Typography variant="p3">bath</Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            pr="8px"
                            pl="8px"
                            borderRight="1px solid #E9EAEB"
                          >
                            <Typography variant="p2">
                              {isMultifamily ? (
                                formatNumberWithCommas(sqft)
                              ) : (
                                <>
                                  {assetDetails?.sqft
                                    ? formatNumberWithCommas(assetDetails?.sqft)
                                    : 'unknown'}
                                </>
                              )}
                            </Typography>
                            <Typography variant="p3">sq ft</Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            pr="8px"
                            pl="8px"
                          >
                            <Typography variant="p2">
                              {assetDetails?.propertyType === 'SFR'
                                ? 'single-family home'
                                : assetDetails?.propertyType?.toLowerCase()}
                            </Typography>
                            <Typography variant="p3">
                              built {assetDetails?.yearBuilt}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                    {isMultifamily && (
                      <Box
                        display="inline-flex"
                        flexDirection="row"
                        alignItems="flex-start"
                        gap="16px"
                        width="100%"
                        height="100%"
                        sx={{
                          overflowY: 'hidden',
                          overflowX: 'auto',
                        }}
                      >
                        {assetDetailsArray.map((unitDetails, index) => (
                          <Box
                            display="inline-flex"
                            flex="1 1 0"
                            padding="12px"
                            bgcolor="rgba(235, 241, 241, 0.50)"
                            borderRadius="8px"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="flex-start"
                            gap="8px"
                          >
                            <Box
                              display="flex"
                              flexDirection="row"
                              justifyContent="space-between"
                              alignItems="center"
                              width="100%"
                            >
                              <Typography variant="p3" fontWeight="500">
                                {unitDetails?.address?.unit ||
                                  `Unit ${index + 1}`}
                              </Typography>
                              {unitDetails?.leaseStatus && (
                                <Paper
                                  sx={{
                                    borderRadius: '4px',
                                    padding: '2px 4px',
                                    backgroundColor:
                                      tagColorMap[
                                        unitDetails?.leaseStatus || OCCUPIED
                                      ],
                                  }}
                                >
                                  <Typography
                                    variant="c1"
                                    color={
                                      textColorMap[
                                        unitDetails?.leaseStatus || OCCUPIED
                                      ]
                                    }
                                  >
                                    {unitDetails?.leaseStatus === MTM
                                      ? 'OCCUPIED'
                                      : unitDetails?.leaseStatus?.toUpperCase()}
                                  </Typography>
                                </Paper>
                              )}
                            </Box>
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="4px"
                            >
                              <Box
                                display="flex"
                                flexDirection="row"
                                gap="16px"
                              >
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  gap="4px"
                                >
                                  <BedroomIcon height="20px" width="20px" />
                                  <Typography variant="p3">
                                    {unitDetails.beds} bed
                                  </Typography>
                                </Box>
                                <Box
                                  display="flex"
                                  flexDirection="row"
                                  gap="4px"
                                >
                                  <BathroomIcon height="20px" width="20px" />
                                  <Typography variant="p3">
                                    {unitDetails.baths} bath
                                  </Typography>
                                </Box>
                              </Box>
                              <Box display="flex" flexDirection="row" gap="4px">
                                <DimensionsIcon height="20px" width="20px" />
                                <Typography variant="p3">
                                  {unitDetails.sqft
                                    ? formatNumberWithCommas(unitDetails.sqft)
                                    : 'unknown'}{' '}
                                  sq ft
                                </Typography>
                              </Box>
                              <Box display="flex" flexDirection="row" gap="4px">
                                <MoneyOutlinedIcon height="20px" width="20px" />
                                <Typography variant="p3">
                                  {unitDetails.monthlyRentCents
                                    ? `${formatIntegerDollars(
                                        unitDetails.monthlyRentCents / 100,
                                        true
                                      )}/mo`
                                    : ''}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    )}
                  </Box>
                </Paper>
                <Paper
                  sx={{
                    padding: '24px',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    transition: 'background-color 0.2s ease-in-out',
                  }}
                  id="performanceMetrics"
                >
                  <Box display="flex" flexDirection="column" gap="16px">
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Typography variant="p1">
                        Performance Metrics&nbsp;
                      </Typography>
                      <StyledTooltip
                        title={
                          <Typography variant="p3" color="gray8.main">
                            Any absent values will be updated after the
                            following quarter end.
                          </Typography>
                        }
                        enterTouchDelay={0}
                        data-cy="assetDropdownTooltipIcon"
                        placement="right"
                        arrow
                      >
                        <StyledInfoOutline fontSize="medium" color="disabled" />
                      </StyledTooltip>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="8px"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="left"
                        >
                          <Typography variant="p3" color="gray5.main">
                            GROSS YIELD
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="left"
                          gap="8px"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="end"
                            pr="8px"
                            borderRight="1px solid #E9EAEB"
                          >
                            <Typography variant="p1">
                              {(isMultifamily && grossYield !== 0) ||
                              assetDetails?.grossYield ? (
                                <>
                                  {isMultifamily
                                    ? formatPercentage(grossYield, 1)
                                    : formatPercentage(
                                        assetDetails?.grossYield || 0,
                                        1
                                      )}
                                </>
                              ) : (
                                '-'
                              )}
                            </Typography>
                            <Typography variant="p2">in-place</Typography>
                          </Box>
                          {assetDetails?.underwrittenGrossYield && (
                            <Box
                              display="flex"
                              flexDirection="column"
                              alignItems="flex-start"
                              justifyContent="end"
                              mb="3px"
                              gap="8px"
                            >
                              <Typography
                                variant="p3"
                                color={
                                  (isMultifamily &&
                                    grossYield >
                                      assetDetails?.underwrittenGrossYield) ||
                                  (assetDetails?.grossYield || 0) >
                                    assetDetails?.underwrittenGrossYield
                                    ? 'green4.main'
                                    : 'errorRed.main'
                                }
                              >
                                {formatPercentage(
                                  assetDetails?.underwrittenGrossYield,
                                  1
                                )}
                              </Typography>
                              <Typography
                                variant="p4"
                                color={
                                  (isMultifamily &&
                                    grossYield >
                                      assetDetails?.underwrittenGrossYield) ||
                                  (assetDetails?.grossYield || 0) >
                                    assetDetails?.underwrittenGrossYield
                                    ? 'green4.main'
                                    : 'errorRed.main'
                                }
                              >
                                underwritten
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="8px"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="left"
                        >
                          <Typography variant="p3" color="gray5.main">
                            TOTAL RENT
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="left"
                          gap="8px"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="end"
                            pr="8px"
                            borderRight="1px solid #E9EAEB"
                          >
                            <Typography variant="p1">
                              {(isMultifamily && unitsRentedCount > 0) ||
                              ((assetDetails?.leaseStatus === OCCUPIED ||
                                assetDetails?.leaseStatus === MTM) &&
                                (assetDetails?.monthlyRentCents || 0) > 0) ? (
                                <>
                                  {isMultifamily
                                    ? formatIntegerDollars(
                                        monthlyRentCents / 100,
                                        true
                                      )
                                    : formatIntegerDollars(
                                        (assetDetails?.monthlyRentCents || 0) /
                                          100,
                                        true
                                      )}
                                </>
                              ) : (
                                '-'
                              )}
                            </Typography>
                            <Typography variant="p2">in-place</Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="end"
                            mb="3px"
                            gap="8px"
                          >
                            <Typography
                              variant="p3"
                              color={
                                (isMultifamily &&
                                  monthlyRentCents > marketRentCents) ||
                                (assetDetails?.monthlyRentCents || 0) >
                                  (assetDetails?.marketRentCents || 0)
                                  ? 'green4.main'
                                  : 'errorRed.main'
                              }
                            >
                              {(isMultifamily && marketRentCents > 0) ||
                              (!isMultifamily &&
                                (assetDetails?.marketRentCents || 0) > 0) ? (
                                <>
                                  {isMultifamily
                                    ? formatIntegerDollars(
                                        marketRentCents / 100,
                                        true
                                      )
                                    : formatIntegerDollars(
                                        (assetDetails?.marketRentCents || 0) /
                                          100,
                                        true
                                      )}
                                </>
                              ) : (
                                '-'
                              )}
                            </Typography>
                            <Box
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="left"
                            >
                              <Typography
                                variant="p4"
                                color={
                                  (isMultifamily &&
                                    monthlyRentCents > marketRentCents) ||
                                  (!isMultifamily &&
                                    (assetDetails?.monthlyRentCents || 0) >
                                      (assetDetails?.marketRentCents || 0))
                                    ? 'green4.main'
                                    : 'errorRed.main'
                                }
                              >
                                market&nbsp;
                              </Typography>
                              <StyledTooltip
                                title={
                                  <Typography variant="p3" color="gray8.main">
                                    Market rent is updated weekly from Zillow
                                    rental comps and serves as the benchmark for
                                    measuring the Fund&apos;s loss-to-lease.
                                  </Typography>
                                }
                                enterTouchDelay={0}
                                data-cy="assetDropdownTooltipIcon"
                                placement="right"
                                arrow
                              >
                                <StyledInfoOutline
                                  fontSize="medium"
                                  color="disabled"
                                />
                              </StyledTooltip>
                            </Box>
                          </Box>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="flex-start"
                        gap="8px"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="left"
                        >
                          <Typography variant="p3" color="gray5.main">
                            VALUATION
                          </Typography>
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="row"
                          justifyContent="left"
                          gap="8px"
                        >
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="end"
                            pr="8px"
                            borderRight="1px solid #E9EAEB"
                          >
                            <Typography variant="p1">
                              {assetDetails?.propertyValuationHistory &&
                              assetDetails?.propertyValuationHistory[
                                assetDetails?.propertyValuationHistory.length -
                                  1
                              ] &&
                              assetDetails?.propertyValuationHistory[
                                assetDetails?.propertyValuationHistory.length -
                                  1
                              ]?.currentValuationCents !== 0 &&
                              assetDetails?.propertyValuationHistory[0]
                                ?.currentValuationCents !== 0 ? (
                                <>
                                  {`${formatIntegerDollars(
                                    (assetDetails?.propertyValuationHistory[
                                      assetDetails?.propertyValuationHistory
                                        .length - 1
                                    ]?.currentValuationCents || 0) / 100,
                                    true
                                  )}`}
                                </>
                              ) : (
                                '-'
                              )}
                            </Typography>
                            <Typography variant="p2">book value</Typography>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="flex-start"
                            justifyContent="end"
                            mb="3px"
                            gap="8px"
                          >
                            {assetDetails?.propertyValuationHistory &&
                            assetDetails?.propertyValuationHistory[
                              assetDetails?.propertyValuationHistory.length - 1
                            ] &&
                            assetDetails?.propertyValuationHistory[
                              assetDetails?.propertyValuationHistory.length - 1
                            ]?.currentValuationCents !== 0 &&
                            assetDetails?.propertyValuationHistory[0]
                              ?.currentValuationCents !== 0 ? (
                              <>
                                <Typography
                                  variant="p3"
                                  color={
                                    (assetDetails?.propertyValuationHistory[
                                      assetDetails?.propertyValuationHistory
                                        .length - 1
                                    ]?.currentValuationCents || 0) >
                                    (assetDetails?.propertyValuationHistory[0]
                                      ?.currentValuationCents || 0)
                                      ? 'green4.main'
                                      : 'errorRed.main'
                                  }
                                >
                                  {`${formatIntegerDollars(
                                    (assetDetails?.propertyValuationHistory[0]
                                      ?.currentValuationCents || 0) / 100,
                                    true
                                  )}`}
                                </Typography>
                                <Typography
                                  variant="p4"
                                  color={
                                    (assetDetails?.propertyValuationHistory[
                                      assetDetails?.propertyValuationHistory
                                        .length - 1
                                    ]?.currentValuationCents || 0) >
                                    (assetDetails?.propertyValuationHistory[0]
                                      ?.currentValuationCents || 0)
                                      ? 'green4.main'
                                      : 'errorRed.main'
                                  }
                                >
                                  cost basis
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography variant="p3">-</Typography>
                                <Typography variant="p4">cost basis</Typography>
                              </>
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
                {hasValuationData && (
                  <Paper
                    sx={{
                      padding: '24px 24px 8px 24px',
                      borderRadius: '16px',
                      boxShadow: 'none',
                      transition: 'background-color 0.2s ease-in-out',
                    }}
                    id="valuationHistory"
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="left"
                    >
                      <Typography variant="p1">
                        Valuation History&nbsp;
                      </Typography>
                      <StyledTooltip
                        title={
                          <Typography variant="p3" color="gray8.main">
                            Valuations are inclusive of home price appreciation
                            and capital improvements.
                          </Typography>
                        }
                        enterTouchDelay={0}
                        data-cy="assetDropdownTooltipIcon"
                        placement="right"
                        arrow
                      >
                        <StyledInfoOutline fontSize="medium" color="disabled" />
                      </StyledTooltip>
                    </Box>
                    <Box marginTop="8px" width="100%" height="192px">
                      <AssetValuationChart
                        tickFormatterX={tickFormatterX}
                        tickFormatterY={tickFormatterY}
                        valuationGraphData={valuationGraphData}
                      />
                    </Box>
                  </Paper>
                )}
                {hasLeasingData && leasingGraphData.length > 0 && (
                  <Paper
                    sx={{
                      padding: '24px',
                      borderRadius: '16px',
                      boxShadow: 'none',
                      transition: 'background-color 0.2s ease-in-out',
                    }}
                    id="rentalHistory"
                  >
                    <Typography variant="p1">Rental History</Typography>
                    <Box marginTop="8px" width="100%" height="192px">
                      <AssetLeasingChart
                        tickFormatterX={tickFormatterX}
                        tickFormatterY={tickFormatterYLeasing}
                        leasingGraphData={leasingGraphData}
                      />
                    </Box>
                  </Paper>
                )}
                <Paper
                  sx={{
                    padding: '24px',
                    borderRadius: '16px',
                    boxShadow: 'none',
                    transition: 'background-color 0.2s ease-in-out',
                  }}
                  id="assetLifecycle"
                >
                  <Typography variant="p1" fontSize="20px">
                    Asset Lifecycle
                  </Typography>
                  <Grid
                    container
                    columns={12}
                    spacing={1}
                    marginTop="8px"
                    maxHeight="720px"
                    sx={{
                      overflowY: 'auto',
                      '&::-webkit-scrollbar': {
                        width: '8px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#CBCDCF',
                        borderRadius: '8px',
                      },
                    }}
                  >
                    {[...lifecycleData]?.reverse().map((data, index) => {
                      const date = new Date(data.effectiveDateTime)
                      if (
                        eventQuarter !==
                          Math.floor((date.getMonth() + 3) / 3) ||
                        eventYear !== date.getFullYear()
                      ) {
                        eventQuarter = Math.floor((date.getMonth() + 3) / 3)
                        eventYear = date.getFullYear()
                        return (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                variant="p1"
                                fontWeight={500}
                                fontSize="16px"
                              >
                                {tickFormatterX(data.effectiveDateTime)}
                              </Typography>
                            </Grid>
                            {/* do not show first valuation if equal to acquisition price */}
                            {data.type ===
                            AssetLifecycleEventType.VALUATION_UPDATE ? (
                              <>
                                {!(
                                  data.currentValuation === acquisitionPrice &&
                                  index === lifecycleData.length - 1
                                ) && (
                                  <>
                                    <AssetLifecycleEventRow
                                      key={data.effectiveDateTime}
                                      eventType={
                                        AssetLifecycleEventType.VALUATION_UPDATE
                                      }
                                      dateString={data.effectiveDateTime}
                                      amountCents={data.currentValuation}
                                      previousAmount={data.previousValuation}
                                    />
                                    {data.capex > 0 && (
                                      <AssetLifecycleEventRow
                                        key={data.effectiveDateTime}
                                        eventType={
                                          AssetLifecycleEventType.RENOVATION_UPDATE
                                        }
                                        dateString={data.effectiveDateTime}
                                        amountCents={data.capex}
                                      />
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <AssetLifecycleEventRow
                                key={data.effectiveDateTime}
                                eventType={data.type}
                                dateString={data.effectiveDateTime}
                                amountCents={
                                  data.monthlyRent || data.amountCents
                                }
                                previousAmount={data.previousMonthlyRent}
                              />
                            )}
                          </>
                        )
                      } else {
                        return (
                          <>
                            {data.type ===
                            AssetLifecycleEventType.VALUATION_UPDATE ? (
                              <>
                                <AssetLifecycleEventRow
                                  key={data.effectiveDateTime}
                                  eventType={
                                    AssetLifecycleEventType.VALUATION_UPDATE
                                  }
                                  dateString={data.effectiveDateTime}
                                  amountCents={data.currentValuation}
                                  previousAmount={data.previousValuation}
                                />
                                {data.capex > 0 && (
                                  <AssetLifecycleEventRow
                                    key={data.effectiveDateTime}
                                    eventType={
                                      AssetLifecycleEventType.RENOVATION_UPDATE
                                    }
                                    dateString={data.effectiveDateTime}
                                    amountCents={data.capex}
                                  />
                                )}
                              </>
                            ) : (
                              <AssetLifecycleEventRow
                                key={data.effectiveDateTime}
                                eventType={data.type}
                                dateString={data.effectiveDateTime}
                                amountCents={
                                  data.monthlyRent || data.amountCents
                                }
                                previousAmount={data.previousMonthlyRent}
                              />
                            )}
                          </>
                        )
                      }
                    })}
                    {acquisitionPrice > 0 && (
                      <AssetLifecycleEventRow
                        key={acquisitionDate}
                        eventType={AssetLifecycleEventType.ACQUISITION}
                        dateString={acquisitionDate}
                        amountCents={acquisitionPrice}
                      />
                    )}
                  </Grid>
                </Paper>
              </Box>
            )}
          </Grid>
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

AssetViewPage.defaultProps = {
  assetUuid: undefined,
}

export default AssetViewPage
