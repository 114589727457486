import { useMutation, useQuery } from '@apollo/client'
import {
  Core_BasicInvestorInfoSubmittedTaskResolutionDataInput,
  Core_EntityInvestorQualificationFormResolutionDataInput,
  Core_EntityPart1AOption,
  Core_IndividualInvestorQualificationFormResolutionDataInput,
  Core_IndividualPart2DOption,
  Core_OrderV3Task,
  Core_OrderV3TaskKey,
  Core_OrderV3TaskStatus,
  Core_OrganizationType,
  Core_TrustType,
  InvestorGetLegalEntityDocument,
  InvestorGetOrderV3Document,
  InvestorGetOrderV3SubscriptionDocumentSigningUrlDocument,
  InvestorUpdateOrderV3TaskDocument,
} from '@flock/flock-gql-server/src/__generated__/graphql'
import { RouteComponentProps, useLocation, WindowLocation } from '@reach/router'
import {
  FlowData,
  InputType,
  LoadingDisplayProps,
  PageContainer,
  StepConfig,
} from '@flock/shared-ui'
import { Box, Typography, CircularProgress, Grid } from '@mui/material'
import { sessionStore } from '@flock/utils'
import React, { useCallback, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { allStatesLongForm, ORDERS_URL } from '../../../../constants'
import PageWrapper from '../../../SharedComponents/PageWrapper'
import FormStepComponent, {
  FormStepComponentProps,
  InjectedFormStepProps,
} from '../../FormStepComponent'
import StepperForm, { SharedStepperComponentProps } from '../../StepperForm'
import AccreditationAndIdentityVerificationStep, {
  AccreditationAndIdentityVerificationInjectedProps,
} from './AccreditationAndIdentityVerificationStep'
import SubscriptionDocumentSigning, {
  SubscriptionDocumentSigningInjectedProps,
} from './SubscriptionDocumentsStep'
import CountriesDropDownOptions from './CountriesDropDownOptions'
import LoadingCardPage from '../../../SharedComponents/LoadingCardPage'
import ErrorCardPage from '../../../SharedComponents/ErrorCardPage'
import { useInvestorAccountContext } from '../../../InvestorAccountContext'
import { LpOnboardingFlowData } from './lpOnboardingTypes'

const progressDisplaySteps = [
  {
    label: 'Investor Information',
    value: 'investorInformation',
  },
  {
    label: 'Accreditation and Identity Verification',
    value: 'accreditationAndIdentityVerification',
  },
  {
    label: 'Subscription Document Signing',
    value: 'subscriptionDocumentSigning',
  },
]

const individualPart2D: { [entity: string]: Core_IndividualPart2DOption } = {
  individual:
    Core_IndividualPart2DOption.IndividualPart2DOptionOptionSubscriberIsHumanBeing,
  joint:
    Core_IndividualPart2DOption.IndividualPart2DOptionOptionSubscriberIsJointTenancy,
  revocable_grant_trust:
    Core_IndividualPart2DOption.IndividualPart2DOptionOptionSubscriberIsRevocableGrantOrTrust,
  ira: Core_IndividualPart2DOption.IndividualPart2DOptionOptionSubscriberIsIndividualRetirementAccount,
  self_directed_retirement:
    Core_IndividualPart2DOption.IndividualPart2DOptionOptionSubscriberIsSelfDirectedRetirementAccount,
  unspecified:
    Core_IndividualPart2DOption.IndividualPart2DOptionOptionUnspecified,
}

const entityPart1AOption: { [entity: string]: Core_EntityPart1AOption } = {
  '12A':
    Core_EntityPart1AOption.EntityPart1AOptionOptionAccreditedEntityCorpLlcPartnershipAssetsOver_5Million,
  '12B':
    Core_EntityPart1AOption.EntityPart1AOptionOptionAccreditedEntityIrsCode_1986AssetsOver_5Million,
  '12C':
    Core_EntityPart1AOption.EntityPart1AOptionOptionAccreditedEntityMassachusettsTrustAssetsOver_5Million,
}

const organizationType: { [entity: string]: Core_OrganizationType } = {
  broker_or_dealer: Core_OrganizationType.OrganizationTypeBrokerOrDealer,
  insurance_company: Core_OrganizationType.OrganizationTypeInsuranceCompany,
  investment_Company: Core_OrganizationType.OrganizationTypeInvestmentCompany,
  issuer_investment_company:
    Core_OrganizationType.OrganizationTypeIssuerInvestmentCompany,
  non_profit: Core_OrganizationType.OrganizationTypeNonProfit,
  non_governmental_pension_plan:
    Core_OrganizationType.OrganizationTypeNonGovernmentalPensionPlan,
  banking_or_thrift_institution:
    Core_OrganizationType.OrganizationTypeBankingOrThriftInstitution,
  governmental_entity: Core_OrganizationType.OrganizationTypeGovernmentalEntity,
  governmental_pension_plan:
    Core_OrganizationType.OrganizationTypeGovernmentalPensionPlan,
  sovereign_wealth_fund_or_foreign_institution:
    Core_OrganizationType.OrganizationTypeSovereignWealthFundOrForeignInstitution,
  other: Core_OrganizationType.OrganizationTypeOther,
}

const trustType: { [entity: string]: Core_TrustType } = {
  irrevocable: Core_TrustType.TrustTypeIrrevocableTrust,
  revocable: Core_TrustType.TrustTypeRevocableTrust,
}

const LpOnboardingLoadingComponent = ({ text }: LoadingDisplayProps) => (
  <>
    <Grid item xs={3} />

    <Grid item xs={6}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt="16px"
        gap="32px"
      >
        <Typography variant="p2" color="gray8.main">
          {text}
        </Typography>
        <CircularProgress />
      </Box>
    </Grid>
    <Grid item xs={3} />
  </>
)

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LpOnboardingPage = (_: RouteComponentProps) => {
  const { investorContextLoading } = useInvestorAccountContext()

  const { state } = useLocation() as WindowLocation<{
    orderUuid: string
    legalEntityUuid: string
  }>

  if (state?.legalEntityUuid && state?.orderUuid) {
    sessionStore.setItem(
      'lp_onboarding_legal_entity_uuid',
      state.legalEntityUuid
    )
    sessionStore.setItem('lp_onboarding_order_uuid', state.orderUuid)
  }

  const legalEntityUuid =
    state?.legalEntityUuid ||
    sessionStore.getItem('lp_onboarding_legal_entity_uuid') ||
    ''
  const sessionOrderUuid =
    state?.orderUuid || sessionStore.getItem('lp_onboarding_order_uuid') || ''

  const [updateTask] = useMutation(InvestorUpdateOrderV3TaskDocument)
  const [initialStep, setInitialStep] = useState<string>('investorInformation')

  const [pageData, setPageData] = useState<{ [key: string]: any }>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState(false)
  const { refetch: refetchLegalEntity } = useQuery(
    InvestorGetLegalEntityDocument,
    {
      skip: true,
    }
  )

  const { refetch: refetchOrders } = useQuery(InvestorGetOrderV3Document, {
    skip: true,
  })
  const { refetch: refetchUrl } = useQuery(
    InvestorGetOrderV3SubscriptionDocumentSigningUrlDocument,
    { skip: true }
  )
  const handleSubdocsUrl = async () => {
    try {
      const result = await refetchUrl({
        input: { legalEntityUuid, orderUuid: sessionOrderUuid },
      })
      const { url } = result.data.getOrderV3SubscriptionDocumentUrl!
      return url
    } catch (e) {
      setError(true)
      return null
    }
  }
  const initializePageData = useCallback(async () => {
    const updatedPageData: { [key: string]: any } = {}

    const { data: legalEntityData, error: legalEntityError } =
      await refetchLegalEntity({
        input: {
          legalEntityUuid,
        },
      })
    if (legalEntityError) {
      setError(true)
    }

    updatedPageData.legalEntityData = legalEntityData?.legalEntity?.legalEntity

    const { data: ordersData, error: ordersError } = await refetchOrders({
      input: {
        uuid: sessionOrderUuid,
      },
    })
    if (ordersError) {
      setError(true)
    }

    const mostRecentOrder = ordersData?.getOrderV3?.order

    const lpOnboardingTasks: Core_OrderV3Task[] =
      mostRecentOrder?.orderLegalEntities?.find(
        (orderLegalEntity) =>
          orderLegalEntity.legalEntityUuid ===
          updatedPageData.legalEntityData.uuid
      )?.lpOnboardingProcess!
    updatedPageData.orderUuid = mostRecentOrder?.uuid

    const basicInvestorInfoTask = lpOnboardingTasks.find(
      (task: Core_OrderV3Task) =>
        task.key ===
        Core_OrderV3TaskKey.OrderV3TaskKeyBasicInvestorInfoSubmittedLegalEntity
    )
    const basicInvestorInfoTaskUuid = basicInvestorInfoTask?.taskUuid
    updatedPageData.basicInvestorInfoTaskUuid = basicInvestorInfoTaskUuid

    const iqsTask = lpOnboardingTasks.find(
      (task: Core_OrderV3Task) =>
        task.key ===
        Core_OrderV3TaskKey.OrderV3TaskKeyInvestorQualificationFormCompletedLegalEntity
    ) as Core_OrderV3Task
    const iqsTaskUuid = iqsTask?.taskUuid
    updatedPageData.iqsTaskUuid = iqsTaskUuid

    const investorVerificationRequestedTask = lpOnboardingTasks?.find(
      (task: Core_OrderV3Task) =>
        task.key ===
        Core_OrderV3TaskKey.OrderV3TaskKeyIdentityAndAccreditationVerificationRequestedLegalEntity
    ) as Core_OrderV3Task
    updatedPageData.investorVerificationRequestedTaskUuid =
      investorVerificationRequestedTask?.taskUuid

    const subscriptionDocumentsSignatureSignedTask = lpOnboardingTasks?.find(
      (task: Core_OrderV3Task) =>
        task.key ===
        Core_OrderV3TaskKey.OrderV3TaskKeySubscriptionDocumentsSignedLegalEntity
    ) as Core_OrderV3Task

    const subscriptionDocumentsSignatureRequestedTask = lpOnboardingTasks?.find(
      (task: Core_OrderV3Task) =>
        task.key ===
        Core_OrderV3TaskKey.OrderV3TaskKeySubscriptionDocumentsSignatureRequestedLegalEntity
    ) as Core_OrderV3Task

    if (
      !basicInvestorInfoTask ||
      !iqsTask ||
      !investorVerificationRequestedTask ||
      !subscriptionDocumentsSignatureSignedTask ||
      !subscriptionDocumentsSignatureRequestedTask
    ) {
      setError(true)
    }

    updatedPageData.subscriptionDocumentsSignatureSignedTaskUuid =
      subscriptionDocumentsSignatureSignedTask?.taskUuid

    updatedPageData.isSigningComplete =
      subscriptionDocumentsSignatureSignedTask?.status ===
        Core_OrderV3TaskStatus.OrderV3TaskStatusStarted ||
      subscriptionDocumentsSignatureSignedTask?.status ===
        Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted

    updatedPageData.investorVerificationRequestedTaskUuid =
      investorVerificationRequestedTask?.taskUuid

    updatedPageData.subscriptionDocumentsSignatureRequestedTask =
      subscriptionDocumentsSignatureRequestedTask

    // determine initial step
    if (iqsTask?.status === Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted) {
      if (
        investorVerificationRequestedTask?.status ===
        Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted
      ) {
        setInitialStep('subscriptionDocumentSigning')
      } else {
        setInitialStep('accreditationAndIdentityVerification')
      }
    }

    setPageData(updatedPageData)
    setLoading(false)
  }, [legalEntityUuid, sessionOrderUuid, refetchLegalEntity, refetchOrders])

  useEffect(() => {
    if (!investorContextLoading && legalEntityUuid) {
      initializePageData()
    }
  }, [investorContextLoading, legalEntityUuid, initializePageData])

  if (error) {
    return <ErrorCardPage />
  }
  if (loading) {
    return <LoadingCardPage text="Preparing Order Onboarding..." />
  }

  const injectProps = (stepName: string) => (flowData: FlowData) => ({
    flowData,
    stepData: flowData[stepName] || {},
  })

  const updateTaskData = async (flowData: any) => {
    const {
      individualInvestor,
      orderUuid,
      investorInformation,
      basicInvestorInfoTaskUuid,
      iqsTaskUuid,
    } = flowData

    const individualIqsData = individualInvestor
      ? ({
          accreditedByNetWorth: investorInformation.accreditedByNetWorth
            ? investorInformation.accreditedByNetWorth
            : false,
          accreditedByIncome: investorInformation.accreditedByIncome
            ? investorInformation.accreditedByIncome
            : false,
          accreditedByLicense: investorInformation.accreditedByLicense
            ? investorInformation.accreditedByLicense
            : false,
          accreditedByFamilyOffice: investorInformation.accreditedByFamilyOffice
            ? investorInformation.accreditedByFamilyOffice
            : false,
          accreditedByRoleWithIssuer:
            investorInformation.accreditedByRoleWithIssuer
              ? investorInformation.accreditedByRoleWithIssuer
              : false,
          subjectToDisqualifyingEvent:
            investorInformation.subjectToDisqualifyingEvent === 'true',
          investingThroughPlan:
            investorInformation.investingThroughPlan === 'true',
          investingThroughIndividualRetirementAccount:
            investorInformation.investingThroughIndividualRetirementAccount ===
            'true',
          subjectToErisa: investorInformation.subjectToErisa === 'true',
          subscribingEntityType:
            individualPart2D[investorInformation.subscribingEntityType],
          jointSubscriberType: investorInformation.jointSubscriberType,
          jointSubscriberTypeOther:
            investorInformation.jointSubscriberTypeOther,
          jointContributorName: investorInformation.jointContributorName,
          jointContributorEmail: investorInformation.jointContributorEmail,
          soleSettlorName: investorInformation.soleSettlorName,
          iraAccountHolderName: investorInformation.iraAccountHolderName,
          selfDirectedRetirementAccountHolder:
            investorInformation.selfDirectedRetirementAccountHolder,
          authorizedRepresentativeName:
            investorInformation.authorizedRepresentativeName,
          authorizedRepresentativeTitle:
            investorInformation.authorizedRepresentativeTitle,
          authorizedRepresentativeEmail:
            investorInformation.authorizedRepresentativeEmail,
          citizenship: investorInformation.citizenship,
          domicile: investorInformation.domicile,
          foreignOwnership: investorInformation.foreignOwnership === 'true',
          foreignOwnershipPercentage:
            investorInformation.foreignOwnershipPercentage
              ? parseInt(investorInformation.foreignOwnershipPercentage, 10)
              : undefined,
          discretionaryAuthorityOrControlOverPartnershipAssets:
            investorInformation.discretionaryAuthorityOrControlOverPartnershipAssets ===
            'true',
          otherBeneficialInterestHolders:
            investorInformation.otherBeneficialInterestHolders === 'true',
        } as Core_IndividualInvestorQualificationFormResolutionDataInput)
      : undefined

    const entityIqsData = individualInvestor
      ? undefined
      : ({
          accreditedByAccreditedOwners:
            investorInformation.accreditedByAccreditedOwners
              ? investorInformation.accreditedByAccreditedOwners
              : false,
          accreditedByFamilyOffice: investorInformation.accreditedByFamilyOffice
            ? investorInformation.accreditedByFamilyOffice
            : false,
          accreditedByOther: investorInformation.accreditedByOther
            ? investorInformation.accreditedByOther
            : false,
          accreditedByTotalAssets: investorInformation.accreditedByTotalAssets
            ? investorInformation.accreditedByTotalAssets
            : false,
          accreditedByTotalAssetsEntityType:
            entityPart1AOption[
              investorInformation.accreditedByTotalAssetsEntityType
            ],
          accreditedByTrustTotalAssets:
            investorInformation.accreditedByTrustTotalAssets
              ? investorInformation.accreditedByTrustTotalAssets
              : false,
          authorizedRepresentativeEmail:
            investorInformation.authorizedRepresentativeEmail,
          authorizedRepresentativeName:
            investorInformation.authorizedRepresentativeName,
          authorizedRepresentativeTitle:
            investorInformation.authorizedRepresentativeTitle,
          domicile: investorInformation.domicile,
          entityIsCorporation: investorInformation.entityIsCorporation
            ? investorInformation.entityIsCorporation
            : false,
          entityIsGeneralPartnership:
            investorInformation.entityIsGeneralPartnership
              ? investorInformation.entityIsGeneralPartnership
              : false,
          entityIsLimitedPartnership:
            investorInformation.entityIsLimitedPartnership
              ? investorInformation.entityIsLimitedPartnership
              : false,
          entityIsLlc: investorInformation.entityIsLLC
            ? investorInformation.entityIsLLC
            : false,
          entityIsTrust: investorInformation.entityIsTrust
            ? investorInformation.entityIsTrust
            : false,
          freedomOfInformationAct:
            investorInformation.freedomOfInformationAct === 'true',
          jurisdiction: investorInformation.jurisdiction,
          organizationType:
            organizationType[investorInformation.organizationType],
          qualifiedForeignPensionFund:
            investorInformation.qualifiedForeignPensionFund === 'true',
          sovereignImmunity: investorInformation.sovereignImmunity === 'true',
          subjectToDisqualifyingEvent:
            investorInformation.subjectToDisqualifyingEvent === 'true',
          trustType: trustType[investorInformation.trustType],
          foreignOwnership: investorInformation.foreignOwnership === 'true',
          foreignOwnershipPercentage:
            investorInformation.foreignOwnershipPercentage
              ? parseInt(investorInformation.foreignOwnershipPercentage, 10)
              : undefined,
          discretionaryAuthorityOrControlOverPartnershipAssets:
            investorInformation.discretionaryAuthorityOrControlOverPartnershipAssets ===
            'true',
          otherBeneficialInterestHolders:
            investorInformation.otherBeneficialInterestHolders === 'true',
        } as Core_EntityInvestorQualificationFormResolutionDataInput)

    const basicData = {
      cashDistributionEnabled: false,
      flexibleDistributionPercent: 0,
    } as Core_BasicInvestorInfoSubmittedTaskResolutionDataInput

    await updateTask({
      variables: {
        input: {
          orderUuid,
          taskData: {
            taskUuid: basicInvestorInfoTaskUuid,
            status: Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted,
            resolutionData: {
              data: {
                basicInvestorInfoSubmittedTaskResolutionData: basicData,
              },
            },
          },
        },
      },
    })

    await updateTask({
      variables: {
        input: {
          orderUuid,
          taskData: {
            taskUuid: iqsTaskUuid,
            status: Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted,
            resolutionData: {
              data: {
                investorQualificationFormCompletedTaskResolutionData: {
                  individualInvestorQualificationFormResolutionData:
                    individualInvestor ? individualIqsData : undefined,
                  entityInvestorQualificationFormResolutionData:
                    individualInvestor ? undefined : entityIqsData,
                },
              },
            },
          },
        },
      },
    })
  }

  const investorInformationStep: StepConfig<
    LpOnboardingFlowData,
    {},
    FormStepComponentProps,
    InjectedFormStepProps
  > = {
    stepName: 'investorInformation',
    component: FormStepComponent,
    injectProps: injectProps('investorInformation'),
    componentProps: {
      stepName: 'investorInfo',
      progress: 'investorInformation',
      hideBack: true,
      inputConfigs: [
        {
          name: 'investorAccreditation',
          type: InputType.Section,
          props:
            pageData.legalEntityData.type === 'individual'
              ? {
                  title: 'Investor Accreditation',
                  inputConfigs: [
                    {
                      name: 'accreditedBy',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography variant="p2">
                            Please select the following categories that qualify
                            you as an “accredited investor.” Select all that
                            apply.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'accreditedBySubtitle',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography variant="p3" color="gray5.main">
                            Note that net worth does not include your primary
                            residence.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'accreditedByNetWorth',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'A person whose individual net worth or joint net worth with a spouse exceeds $1,000,000.',
                      },
                    },
                    {
                      name: 'accreditedByIncome',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'A person who had an individual income over of $200,000, or income with a spouse over $300,000, over the 2 most recent consecutive years and reasonably expects to continue those earnings in the current year.',
                      },
                    },
                    {
                      name: 'accreditedByLicense',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'A person who currently holds a General Securities Representative license (Series 7), Private Offerings Representative license (Series 82), or Investment Advisor Representative license (Series 65).',
                      },
                    },
                    {
                      name: 'accreditedByFamilyOffice',
                      type: InputType.Checkbox,
                      props: {
                        label: `A "family office" where (A) the office has total assets under management in excess of $5,000,000, (B) the office is not formed for the specific purpose investing in Flock OP (C) the office has knowledge and experience in financial and business matters and is capable of evaluating the merits and risks of an investment in the limited partners (that is you) interests.`,
                      },
                    },
                    {
                      name: 'accreditedByRoleWithIssuer',
                      type: InputType.Checkbox,
                      props: {
                        label: `A person who is a director, executive officer, or general partner of Flock Homes OP.`,
                      },
                    },
                    {
                      name: 'subjectToDisqualifyingEvent',
                      type: InputType.RadioSelect,
                      props: {
                        label:
                          'Have you been subject to a disqualifying event?',
                        labelSize: 'small',
                        defaultValue: 'false',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'investingThroughPlan',
                      type: InputType.RadioSelect,
                      props: {
                        label:
                          'Are you investing through an IRA or a self-directed account in an “employee benefit plan” that is subject to custodian?',
                        labelSize: 'small',
                        defaultValue: 'false',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'investingThroughIndividualRetirementAccount',
                      type: InputType.RadioSelect,
                      renderIf: (watchedFields: any) =>
                        watchedFields.investingThroughPlan === 'true',
                      props: {
                        label:
                          'Are you investing through an IRA and is the decision to invest being made by the IRA owner?',
                        labelSize: 'small',
                        defaultValue: 'false',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'subjectToErisa',
                      type: InputType.RadioSelect,
                      props: {
                        label: 'Are you subject to ERISA?',
                        caption: `ERISA stands for the Employee Retirement Income Security Act of 1974. If you're subject to ERISA it means you're tax-exempt.`,
                        labelSize: 'small',
                        defaultValue: 'false',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'subscribingEntityType',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label: `Please select the category that best describes how you're investing.`,
                        labelSize: 'small',
                        options: [
                          {
                            label: 'As an individual human being',
                            value: 'individual',
                          },
                          {
                            label:
                              'As a joint tenancy comprised solely of individual human beings',
                            value: 'joint',
                          },
                          {
                            label: 'Through a revocable grant trust',
                            value: 'revocable_grant_trust',
                          },
                          {
                            label: 'Through an IRA account',
                            value: 'ira',
                          },
                          {
                            label: 'Through a self-directed retirement account',
                            value: 'self_directed_retirement',
                          },
                        ],
                      },
                    },
                    {
                      name: 'jointSubscriberType',
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType === 'joint',
                      required: true,
                      type: InputType.Dropdown,
                      props: {
                        label: 'What type of joint tenancy?',
                        options: [
                          {
                            label: 'Tenancy-in-Common',
                            value: 'joint tenancy in common',
                          },
                          {
                            label: 'Joint Tenancy with a Right to Survivorship',
                            value: 'joint tenancy survivorship',
                          },
                          {
                            label: 'Other',
                            value: 'other',
                          },
                        ],
                      },
                    },
                    {
                      name: 'jointSubscriberTypeOther',
                      renderIf: (watchedFields: any) =>
                        watchedFields.jointSubscriberType === 'other',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label:
                          'If other, please enter the type of joint tenancy.',
                      },
                    },
                    {
                      name: 'jointContributorName',
                      type: InputType.Text,
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType === 'joint',
                      required: true,
                      props: {
                        format: 'fullName',
                        label: 'Joint Contributor Name',
                      },
                    },
                    {
                      name: 'jointContributorEmail',
                      type: InputType.Text,
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType === 'joint',
                      required: true,
                      props: {
                        format: 'email',
                        label: 'Joint Contributor Email',
                      },
                    },
                    {
                      name: 'soleSettlorName',
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType ===
                        'revocable_grant_trust',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label:
                          'What is the name of the sole settlor of this trust?',
                      },
                    },
                    {
                      name: 'iraAccountHolderName',
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType === 'ira',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label: 'What is the name of the account holder?',
                      },
                    },
                    {
                      name: 'selfDirectedRetirementAccountHolder',
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType ===
                        'self_directed_retirement',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label: 'What is the name of the account holder?',
                      },
                    },
                    {
                      name: 'authorizedRepresentativeName',
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType ===
                          'revocable_grant_trust' ||
                        watchedFields.subscribingEntityType === 'ira' ||
                        watchedFields.subscribingEntityType ===
                          'self_directed_retirement',
                      required: true,
                      labelText:
                        'Who will be signing on behalf of your entity?',
                      type: InputType.Text,
                      props: {
                        label: 'Authorized Representative Name',
                      },
                    },
                    {
                      name: 'authorizedRepresentativeTitle',
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType ===
                          'revocable_grant_trust' ||
                        watchedFields.subscribingEntityType === 'ira' ||
                        watchedFields.subscribingEntityType ===
                          'self_directed_retirement',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label: 'Authorized Representative Title',
                      },
                    },
                    {
                      name: 'authorizedRepresentativeEmail',
                      renderIf: (watchedFields: any) =>
                        watchedFields.subscribingEntityType ===
                          'revocable_grant_trust' ||
                        watchedFields.subscribingEntityType === 'ira' ||
                        watchedFields.subscribingEntityType ===
                          'self_directed_retirement',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label: 'Authorized Representative Email',
                        format: 'email',
                      },
                    },
                    {
                      name: 'citizenship',
                      type: InputType.Dropdown,
                      required: true,
                      props: {
                        label: 'Where do you have citizenship?',
                        options: CountriesDropDownOptions,
                        InputLabelProps: {
                          shrink: true,
                        },
                      },
                    },
                    {
                      name: 'domicile',
                      type: InputType.Text,
                      required: true,
                      props: {
                        label:
                          'Where do you currently live? Please specify city with U.S. state or non-U.S. jurisdiction.',
                        format: 'cityState',
                        placeholder: `"City, State" or "City, Jurisdiction"`,
                      },
                    },
                  ],
                }
              : {
                  title: 'Investor Accreditation',
                  inputConfigs: [
                    {
                      name: 'accreditedBy',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography variant="p2">
                            Please select the following categories that qualify
                            you as an “accredited investor.” Select all that
                            apply.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'accreditedByTotalAssets',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'An entity that has total assets in excess of $5,000,000',
                      },
                    },
                    {
                      name: 'accreditedByTotalAssetsEntityType',
                      type: InputType.Dropdown,
                      renderIf: (watchedFields: any) =>
                        watchedFields.accreditedByTotalAssets === true,
                      props: {
                        label: 'What type of entity?',
                        options: [
                          {
                            label: 'A Corporation, LLC, or Partnership',
                            value: '12A',
                          },
                          {
                            label: 'A 501(c)(3) c',
                            value: '12B',
                          },
                          {
                            label: 'A Massachusetts or similar business trust',
                            value: '12C',
                          },
                        ],
                      },
                    },
                    {
                      name: 'accreditedByTrustTotalAssets',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'A trust with total assets in excess of $5,000,000 and not formed for the purpose of investing in Flock OP LP.',
                      },
                    },
                    {
                      name: 'accreditedByAccreditedOwners',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'An entity or trust in which all entity owners are accredited investors on an individual level.',
                      },
                    },
                    {
                      name: 'accreditedByFamilyOffice',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'A family office with total assets in excess of $5,000,000 and not formed for the purpose of investing in Flock OP LP.',
                      },
                    },
                    {
                      name: 'accreditedByOther',
                      type: InputType.Checkbox,
                      props: {
                        label: 'Other',
                      },
                    },
                    {
                      name: 'accreditedByOtherHelperText',
                      renderIf: (watchedFields: any) =>
                        watchedFields.accreditedByOther === true,
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography color="gray6.main" variant="p2">
                            Please reach out to our team for assistance if this
                            selection applies to you.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'subjectToDisqualifyingEvent',
                      type: InputType.RadioSelect,
                      props: {
                        label:
                          'Have you been subject to a disqualifying event?',
                        defaultValue: 'false',
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'investingThroughBenefitPlan',
                      type: InputType.RadioSelect,
                      props: {
                        label: 'Are you investing through a benefit plan?',
                        defaultValue: 'false',
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'investingThroughBenefitPlanHelperText',
                      renderIf: (watchedFields: any) =>
                        watchedFields.investingThroughBenefitPlan === 'true',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography color="gray6.main" variant="p2">
                            Please reach out to our team for assistance if this
                            selection applies to you.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'entityTypeQuestion',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography variant="p2">
                            Please select the types of entities that best
                            describe how you&apos;re investing. Select all that
                            apply.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'entityIsCorporation',
                      type: InputType.Checkbox,
                      props: {
                        label: 'Corporation',
                      },
                    },
                    {
                      name: 'entityIsGeneralPartnership',
                      type: InputType.Checkbox,
                      props: {
                        label: 'General Partnership',
                      },
                    },
                    {
                      name: 'entityIsLimitedPartnership',
                      type: InputType.Checkbox,
                      props: {
                        label: 'Limited Partnership',
                      },
                    },
                    {
                      name: 'entityIsLLC',
                      type: InputType.Checkbox,
                      props: {
                        label: 'Limited Liability Company (LLC)',
                      },
                    },
                    {
                      name: 'entityIsTrust',
                      type: InputType.Checkbox,
                      props: {
                        label: 'Trust',
                      },
                    },
                    {
                      name: 'trustType',
                      renderIf: (watchedFields: any) =>
                        watchedFields.entityIsTrust === true,
                      required: true,
                      type: InputType.Dropdown,
                      props: {
                        label: 'What type of trust?',
                        options: [
                          {
                            label: 'Irrevocable',
                            value: 'irrevocable',
                          },
                          {
                            label: 'Revocable',
                            value: 'revocable',
                          },
                        ],
                      },
                    },
                    {
                      name: 'entityIsOther',
                      type: InputType.Checkbox,
                      props: {
                        label:
                          'Other (Please reach out to our team for assistance if this selection applies to you.',
                      },
                    },
                    {
                      name: 'entityIsOtherHelperText',
                      renderIf: (watchedFields: any) =>
                        watchedFields.entityIsOther === true,
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography color="gray6.main" variant="p2">
                            Please reach out to our team for assistance if this
                            selection applies to you.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'organizationType',
                      type: InputType.Dropdown,
                      props: {
                        label: `Please select the type of organization that best describes how you're investing.`,
                        options: [
                          {
                            label: 'Registered broker dealer',
                            value: 'broker_or_dealer',
                          },
                          {
                            label: 'Insurance company',
                            value: 'insurance_company',
                          },
                          {
                            label: 'Registered investment company ',
                            value: 'investment_Company',
                          },
                          {
                            label:
                              'Issuer that would be an “investment company”',
                            value: 'issuer_investment_company',
                          },
                          { label: 'Non-profit', value: 'non_profit' },
                          {
                            label: 'Pension plan',
                            value: 'non_governmental_pension_plan',
                          },
                          {
                            label: 'Banking or thrift institution',
                            value: 'banking_or_thrift_institution',
                          },
                          {
                            label: 'State or municipal government entity',
                            value: 'governmental_entity',
                          },
                          {
                            label:
                              'State or municipal governmental pension plan',
                            value: 'governmental_pension_plan',
                          },
                          {
                            label:
                              'Sovereign wealth fund or foreign official institution',
                            value:
                              'sovereign_wealth_fund_or_foreign_institution',
                          },
                          { label: 'None of the above', value: 'other' },
                        ],
                      },
                    },
                    {
                      name: 'authorizedRepresentativeName',
                      required: true,
                      labelText:
                        'Who will be signing on behalf of your entity?',
                      type: InputType.Text,
                      props: {
                        label: 'Authorized Representative Name',
                      },
                    },
                    {
                      name: 'authorizedRepresentativeTitle',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label: 'Authorized Representative Title',
                      },
                    },
                    {
                      name: 'authorizedRepresentativeEmail',
                      required: true,
                      type: InputType.Text,
                      props: {
                        label: 'Authorized Representative Email',
                        format: 'email',
                      },
                    },
                    {
                      name: 'jurisdiction',
                      type: InputType.Dropdown,
                      required: true,
                      props: {
                        label:
                          'In what U.S. jurisdiction is your entity registered?',
                        options: allStatesLongForm.map((stateString) => ({
                          label: stateString,
                          value: stateString,
                        })),
                      },
                    },
                    {
                      name: 'domicile',
                      type: InputType.Text,
                      required: true,
                      props: {
                        label:
                          'Where do you currently live? Please specify city with U.S. state or non-U.S. jurisdiction.',
                        format: 'cityState',
                        placeholder: `"City, State" or "City, Jurisdiction"`,
                      },
                    },
                  ],
                },
        },
        {
          name: 'unlikelyScenarios',
          type: InputType.Section,
          props:
            pageData.legalEntityData.type === 'individual'
              ? {
                  title: 'Unlikely Scenarios',
                  inputConfigs: [
                    {
                      name: 'unlikelyScenarios',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography variant="p2">
                            These questions don&apos;t apply to most investors.
                            If you&apos;re unsure, they likely don&apos;t apply.
                            If any do apply, please update the selection.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'foreignOwnership',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label:
                          'Does your investment in Flock OP have one or more direct or indirect owners that are foreign persons in relation to Section 897(h)(4)(B) of the Code?',
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'foreignOwnershipPercentage',
                      type: InputType.Text,
                      renderIf: (watchedFields: any) =>
                        watchedFields.foreignOwnership === 'true',
                      required: true,
                      props: {
                        format: 'percent',
                        label:
                          'What percentage of value (1-99) is owned by the foreign persons?',
                        pattern: /^[1-9][0-9]?$|^100$/g,
                        helperText:
                          'Please enter a whole number value between 1 to 99.',
                        formatProps: {
                          isAllowed: (values: any) => {
                            const { floatValue } = values
                            return (
                              floatValue === undefined ||
                              (floatValue < 100 && floatValue > 0)
                            )
                          },
                        },
                      },
                    },
                    {
                      name: 'discretionaryAuthorityOrControlOverPartnershipAssets',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label:
                          'Are you general partner of Flock OP or have any authority to make or advise on investment decision of the Flock OP Fund?',
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'otherBeneficialInterestHolders',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label: `Will anyone else have a beneficial interest in the investment you're making?`,
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                  ],
                }
              : {
                  title: 'Unlikely Scenarios',
                  inputConfigs: [
                    {
                      name: 'unlikelyScenarios',
                      type: InputType.CustomComponent,
                      props: {
                        component: (
                          <Typography variant="p2">
                            These questions don&apos;t apply to most investors.
                            If you&apos;re unsure, they likely don&apos;t apply.
                            If any do apply, please update the selection.
                          </Typography>
                        ),
                      },
                    },
                    {
                      name: 'foreignOwnership',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label:
                          'Will your entity have one or more direct or indirect owners that are foreign persons in relation to Section 897(h)(4)(B) of the Code)?',
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'foreignOwnershipPercentage',
                      type: InputType.Text,
                      renderIf: (watchedFields: any) =>
                        watchedFields.foreignOwnership === 'true',
                      required: true,
                      props: {
                        format: 'percent',
                        label:
                          'What percentage of value (1-99) is owned by the foreign persons?',
                        pattern: /^[1-9][0-9]?$|^100$/g,
                        helperText:
                          'Please enter a whole number value between 1 to 99.',
                        formatProps: {
                          isAllowed: (values: any) => {
                            const { floatValue } = values
                            return (
                              floatValue === undefined ||
                              (floatValue < 100 && floatValue > 0)
                            )
                          },
                        },
                      },
                    },
                    {
                      name: 'discretionaryAuthorityOrControlOverPartnershipAssets',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label:
                          'Are you general partner of Flock OP or have any authority to make or advise on investment decision of the Flock OP Fund?',
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'otherBeneficialInterestHolders',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label: `Will anyone else have a beneficial interest in the investment you're making?`,
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'sovereignImmunity',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label:
                          'Are you subject to sovereign immunity as it relates to obligations under this Subscription Agreement or Partnership Agreement?',
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'freedomOfInformationAct',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label:
                          'Are you directly subject to the Freedom of Information Act?',
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                    {
                      name: 'qualifiedForeignPensionFund',
                      type: InputType.RadioSelect,
                      required: true,
                      props: {
                        label: 'Are you a Qualified Foreign Pension Fund?',
                        labelSize: 'small',
                        row: true,
                        options: [
                          {
                            label: 'No',
                            value: 'false',
                          },
                          {
                            label: 'Yes',
                            value: 'true',
                          },
                        ],
                      },
                    },
                  ],
                },
        },
      ],
    },
    prerender: async (flowData: FlowData) => {
      const data = {
        ...flowData,
        investorInformation: {
          email: pageData.legalEntityData.email,
          phoneNumber: [
            '(',
            pageData.legalEntityData.phoneNumber.substr(0, 3),
            ') ',
            pageData.legalEntityData.phoneNumber.substr(3, 3),
            '-',
            pageData.legalEntityData.phoneNumber.substr(6, 4),
          ].join(''),
          fullName: pageData.legalEntityData.name,
        },
      }
      return data as FlowData
    },
    onCompletion: async (flowData: FlowData) => {
      const data = {
        ...flowData,
        selectedLegalEntity: legalEntityUuid,
        individualInvestor: pageData.legalEntityData.type === 'individual',
        orderUuid: pageData.orderUuid,
        basicInvestorInfoTaskUuid: pageData.basicInvestorInfoTaskUuid,
        iqsTaskUuid: pageData.iqsTaskUuid,
      }
      await updateTaskData(data)
      return flowData
    },
    continueAfterComplete: true,
  }

  const accreditationAndIdentityVerificationStep: StepConfig<
    LpOnboardingFlowData,
    {},
    SharedStepperComponentProps,
    AccreditationAndIdentityVerificationInjectedProps
  > = {
    stepName: 'accreditationAndIdentityVerification',
    component: AccreditationAndIdentityVerificationStep,
    componentProps: {
      progress: 'accreditationAndIdentityVerification',
    },
    prerender: async (flowData: FlowData) => {
      const { orderUuid, subscriptionDocumentsSignatureRequestedTask } =
        flowData as {
          orderUuid: string
          subscriptionDocumentsSignatureRequestedTask: Core_OrderV3Task
        }
      // only generate sub docs if it has not been completed yet
      if (
        subscriptionDocumentsSignatureRequestedTask?.status !==
        Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted
      ) {
        // this is not async since the user should be free to interact with the page while this is happening
        updateTask({
          variables: {
            input: {
              orderUuid,
              taskData: {
                taskUuid: subscriptionDocumentsSignatureRequestedTask?.taskUuid,
                status: Core_OrderV3TaskStatus.OrderV3TaskStatusCompleted,
              },
            },
          },
        })
      }

      return flowData
    },
    injectProps: () => ({
      orderUuid: pageData.orderUuid,
      taskUuid: pageData.investorVerificationRequestedTaskUuid,
      legalEntityUuid,
    }),
    completionLoadingMessage: 'Generating Subscription Documents...',
    // investorVerificationRequestedTask is completed after saving PM tokens
    onCompletion: async (flowData: FlowData) => flowData,
  }

  const subscriptionDocumentSigningStep: StepConfig<
    LpOnboardingFlowData,
    {},
    SharedStepperComponentProps,
    SubscriptionDocumentSigningInjectedProps
  > = {
    stepName: 'subscriptionDocumentSigning',
    component: SubscriptionDocumentSigning,
    componentProps: {
      progress: 'subscriptionDocumentSigning',
    },
    injectProps: (flowData: Partial<LpOnboardingFlowData>) => ({
      docusignURL: flowData.docusignURL as string,
      isSigningComplete: pageData.isSigningComplete,
      orderUuid: pageData.orderUuid,
    }),
    prerenderLoadingMessage: 'Generating Signing Link...',
    prerender: async (flowData: Partial<LpOnboardingFlowData>) => {
      const docusignURL = await handleSubdocsUrl()
      const newFlowData = {
        ...flowData,
        docusignURL: docusignURL as string | undefined,
      }
      return newFlowData
    },
    onCompletion: async (flowData: Partial<LpOnboardingFlowData>) => {
      await updateTask({
        variables: {
          input: {
            orderUuid: pageData.orderUuid,
            taskData: {
              taskUuid: pageData.subscriptionDocumentsSignatureSignedTaskUuid,
              status: Core_OrderV3TaskStatus.OrderV3TaskStatusStarted,
            },
          },
        },
      })
      navigate(`${ORDERS_URL}/${pageData.orderUuid}`)
      return flowData
    },
  }

  const stepConfigs = [
    investorInformationStep,
    accreditationAndIdentityVerificationStep,
    subscriptionDocumentSigningStep,
  ]

  return (
    <PageContainer
      title="FLOCK | Owner Onboarding"
      trackingName="lp-onboarding"
    >
      <PageWrapper>
        <Grid container spacing={4} height="auto">
          <StepperForm
            title="Owner Onboarding"
            description={`This section will take you through the two requirements for subscribing to the fund. (1) completing and executing the subscription booklet and (2) accredited investor verification through Parallel Markets.\n\nOnce the information is completed, Flock will submit your documents to our fund administrator for their review and final approval. If additional documentation is required by our Fund Administrator, Flock will reach out and coordinate the submission of those documents.`}
            initialData={pageData}
            stepConfigs={stepConfigs}
            progressDisplaySteps={progressDisplaySteps}
            initialStep={initialStep}
            loadingComponent={LpOnboardingLoadingComponent}
            useStepComponentLoading
            useHashRouting={false}
            useStepComponentError
          />
        </Grid>
      </PageWrapper>
    </PageContainer>
  )
}

export default LpOnboardingPage
